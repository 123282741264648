<template>
  <div>
    <ledger5Logo />

    <v-card flat width="550" class="mx-auto mt-4">
      <v-card-title>
        <span class="primary--text display-1 ml-3 mt-2 mb-2"
          >Check your mailbox</span
        >
        <h1 class="display-1">
          <v-icon class="pb-1" right large color="primary">mdi-email</v-icon>
        </h1>
        <v-progress-linear
          v-if="showProgressBar"
          indeterminate
          color="success"
        ></v-progress-linear>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="2" />

            <v-col cols="8" class="mt-4">
              <p class="pl-9 primary--text font-weight-medium">
                Enter the verification code you received via E-Mail to confirm
                your E-Mail address and activate your account.
              </p>
              <v-text-field
                rounded
                v-model="email"
                :rules="emailRules"
                validate-on-blur
                type="text"
                prepend-icon="mdi-email"
                label="E-Mail"
                outlined
                dense
              />
              <v-text-field
                rounded
                v-model="code"
                :rules="numberRules"
                validate-on-blur
                prepend-icon="mdi-alpha-c-circle"
                label="Verification Code"
                outlined
                dense
              />
              <div class="text-center">
                <v-btn
                  text
                  plain
                  class="caption text-decoration-underline font-weight-light ml-5"
                  @click="resendCode"
                  >Send me a new code</v-btn
                >
                <v-btn
                  rounded
                  depressed
                  color="success text--white"
                  @click="confirmCode"
                  width="304"
                  class="ml-8 mt-2 mb-6"
                  >Confirm</v-btn
                >
              </div>
            </v-col>

            <v-col cols="2" />
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import ledger5Logo from '@/components/Ledger5Logo.vue'

export default {
  name: 'Confirm',

  components: {
    ledger5Logo
  },

  data() {
    return {
      showProgressBar: false,
      valid: true,
      email: this.$route.query.email,
      code: '',
      numberRules: [
        v => !!v || 'Code is required',
        v =>
          (/^(?=.*\d).+$/.test(v) && v.length == 6) ||
          'Code must be 6 characters'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ]
    }
  },

  methods: {
    async confirmCode() {
      if (this.$refs.form.validate()) {
        // validate form and enable button
        this.showProgressBar = true
        Auth.confirmSignUp(this.email, this.code, {
          forceAliasCreation: true
        })
          .then(data => {
            this.showProgressBar = false
            console.log('CODE CONFIRMATION:', data)
            this.$router.push({ path: '/login', query: { email: this.email } })
          })
          .catch(err => {
            this.showProgressBar = false
            this.$refs.form.reset() // Reset form to enable the user to enter new data
            console.log('CODE CONFIRMATION:', err)
            const notification = {
              type: 'error',
              message: err.message
            }
            this.$store.dispatch('addNotification', notification)
          })
      }
    },

    async resendCode() {
      if (this.$refs.form.validate()) {
        // validate form and enable button
        this.showProgressBar = true
        Auth.resendSignUp(this.email)
          .then(data => {
            this.showProgressBar = false
            console.log('RESEND CODE:', data)
            alert('A new confirmation code was sent to your E-Mail address')
          })
          .catch(err => {
            this.showProgressBar = false
            this.$refs.form.reset() // Reset form to enable the user to enter new data
            console.log('RESEND CODE:', err)
            const notification = {
              type: 'error',
              message: err.message
            }
            this.$store.dispatch('addNotification', notification)
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
